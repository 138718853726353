import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Scroller from "../components/scroller"
import About from "../sections/about"
import AboutExtra from "../sections/aboutExtra"
import Staff from "../sections/staff"
import Events from "../sections/events"
import Gallery from "../sections/gallery"
import Contact from "../sections/contact"
import Athletes from "../sections/athletes"
import Horses from "../sections/horses"

export default class IndexPage extends React.Component {

  render() {

    const { about, contact, staffItems, eventItems, imageItems, athleteItems, horseItems } = this.props.data;
      console.log(eventItems);
    return (
      <Layout>
        <SEO />

        <About title={about.frontmatter.header} />

        <AboutExtra title={about.frontmatter.description} body={about.html} />

        <Staff items={staffItems.nodes} />

        <Events items={eventItems.nodes} />

        <Gallery items={imageItems.nodes} />

        <Contact title={contact.frontmatter.title} body={contact.html} phone={contact.frontmatter.phone} email={contact.frontmatter.email} />
      </Layout>
    )
  }
}


export const imageData = graphql`
  query {
    imageItems: allGalleryYaml {
      nodes {
        name
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
            original: fluid(maxWidth: 1600, maxHeight: 1600) {
              ...GatsbyImageSharpFluid
            }
            preview: fluid(maxWidth: 600, maxHeight: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    about: markdownRemark(fields: {location: {relativePath: {eq: "about.md"}}}) {
      frontmatter {
        header
        description
      }
      html
    }
    contact: markdownRemark(fields: {location: {relativePath: {eq: "contact.md"}}}) {
      frontmatter {
        title
        phone
        email
      }
      html
    }
    staffItems: allStaffYaml {
      nodes {
        id
        name
        title
        position
        image {
          childImageSharp {
            resolutions(height: 400, width: 400) {
              src
            }
          }
        }
        description
      }
    }
    eventItems: allEventsYaml {
      nodes {
        id
        title
        description
        image {
          childImageSharp {
            resolutions(height: 600, width: 800) {
              src
            }
          }
        }
        date
      }
    }
  }
`

import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Markdown from "react-markdown";
import ShowMoreText from "react-show-more-text";

export default class Staff extends Component {

    renderItem(item, first = false) {

        let colclass = "col-md-6";

        if(first) {
            colclass = "col-md-6 offset-md-3"
        }

        return (
            <div className={colclass} style={{padding: 8}}>
                <div className="card h-100">
                    <img src={item.image.childImageSharp.resolutions.src} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h3 className="card-title">{item.name}</h3>
                        <strong>{item.title}</strong>
                        <ShowMoreText
                            more="Περισσότερα"
                            less="Λιγότερα"
                            lines={3}  >
                            <Markdown source={item.description} />
                        </ShowMoreText>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { items } = this.props;

        return (
            <section className="page-section" id="members">
                <div className="container">
                    <StaticQuery
                        query={graphql`query { site: dataYaml { section { text { members } } } }`}
                        render={(data) => (<h2 className="text-center text-white  mt-0">{data.site.section.text.members}</h2>)} />
                    <hr className="divider my-4" />
                    <div className="row row-cols-1 row-cols-md-2">
                        {items.sort((a,b) => a.position > b.position ? 1 : -1).map((item, idx) => this.renderItem(item, idx === 0))}
                    </div>
                </div>
            </section>
        )
    }
}

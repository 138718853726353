import React, { Component } from 'react'
import { SocialIcon } from 'react-social-icons';
import { StaticQuery, graphql } from "gatsby"

export default class Contact extends Component {
    render() {

        const { title, body, phone, email } = this.props;

        return (
            <section className="page-section" id="contact">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center text-white">
                            <h2 className="text-white mt-0">{title}</h2>
                            <hr className="divider my-4" />
                            <div className="mb-5" dangerouslySetInnerHTML={{ __html: body }}></div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 ml-auto text-white  text-center mb-5 mb-lg-0">
                            <i className="fas fa-phone fa-3x mb-3 text-muted"></i>

                            <div>
                                <a href={`tel:${phone}`}>{phone}</a>
                            </div>
                        </div>
                        <div className="col-lg-3 mr-auto text-center text-white">
                            <i className="fas fa-envelope fa-3x mb-3 text-muted"></i>
                            <a className="d-block" href={`mailto:${email}`}>{email}</a>
                        </div>
                    </div>
                    <br /><br /><br />
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center text-white">
                            <h2 className="text-white mt-0">Social Media</h2>
                            <hr className="divider my-4" />
                        </div>
                    </div>
                    <StaticQuery
                        query={graphql`query { site: dataYaml { socialmedia { instagram facebook } } }`}
                        render={(data) => (
                            <div className="row py-5">
                                <div className="col-lg-3 ml-auto text-white  text-center mb-5 mb-lg-0">
                                    <SocialIcon url={data.site.socialmedia.facebook} bgColor="white" />
                                    <div className="py-2">
                                        <a href={data.site.socialmedia.facebook}>Facebook</a>
                                    </div>
                                </div>
                                <div className="col-lg-3 mr-auto text-center text-white">
                                    <SocialIcon url={data.site.socialmedia.instagram} bgColor="white" />
                                    <div className="py-2">
                                        <a href={data.site.socialmedia.instagram}>Instagram</a>
                                    </div>
                                </div>
                            </div>
                        )} />
                </div>
            </section>
        )
    }
}

import React, { Component } from 'react'
import Scroller from '../components/scroller'

export default class About extends Component {

    render() {
        const { title,  body } = this.props;

        return (
          <header className="masthead" id="about">
            <div className="container h-100">
              <div className="row h-100 align-items-center justify-content-center text-center">
                <div className="col-lg-10 align-self-end">
                  <h1 className="text-white font-weight-bold">{title}</h1>
                  <hr className="divider my-4"/>
                </div>
                <div className="col-lg-8 align-self-baseline">
                  <a className="btn btn-secondary btn-xl js-scroll-trigger" href="#contact" onClick={Scroller.handleAnchorScroll}>Επικοινωνία</a>
                </div>
              </div>
            </div>
          </header>
        )
    }
}

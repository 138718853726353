import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import Moment from 'react-moment';
import moment from "moment";
import 'moment/locale/el';

export default class Events extends Component {


    renderItem(item) {
        return (
            <div className="card mb-3" key={item.title}>
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <img src={item.image.childImageSharp.resolutions.src} className="card-img" alt="..." />
                    </div>
                    <div className="col-md-8">
                        <div className="card-body">
                            <h5 className="card-title">
                                {item.title}
                            </h5>
                            <p className="card-text">{item.description}</p>
                        </div>
                    </div>
                </div>
                <div class="card-footer text-right text-white">
                    <Moment format="MM/DD/YYYY">{item.date}</Moment> - <Moment fromNow>{item.date}</Moment>
                </div>
            </div>
        );
    }

    render() {
        const { items } = this.props;


        return (
            <section className="page-section" id="events">
                <div className="container">
                    <StaticQuery
                        query={graphql`query { site: dataYaml { section { text { events } } } }`}
                        render={(data) => (<h2 className="text-center text-white  mt-0">{data.site.section.text.events}</h2>)} />
                    <hr className="divider my-4" />

                    {items.map(item => this.renderItem(item))}
                </div>
            </section>
        )
    }
}

import React, { Component } from 'react'


export default class Athletes extends Component {



    render() {
        const { body, title } = this.props;
        console.log(body);
        return (
            <section className="page-section" id="aboutExtra">
                <div className="container h-100">
                    <div className="row h-100 align-items-center justify-content-center text-center">
                        <div className="col-lg-10 align-self-end">
                            <h1 className="text-white font-weight-bold">{title}</h1>
                            <hr className="divider my-4" />
                        </div>
                        <div className="col-lg-8 align-self-baseline">
                            <div className="text-white-75 font-weight-light mb-5" dangerouslySetInnerHTML={{ __html: body }}></div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

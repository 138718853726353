import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"

export default class Athletes extends Component {

    renderItem(item) {
        return (
            <div className="col mb-4">
                <div className="card h-100">
                    <img src={item.image.childImageSharp.resolutions.src} className="card-img-top" alt="..." />
                    <div className="card-body">
                        <h5 className="card-title">{item.name}</h5>
                        <p className="card-text">{item.description}</p>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { items } = this.props;

        return (
            <section className="page-section" id="athletes">
                <div className="container">

                    <StaticQuery
                        query={graphql`query { site: dataYaml { section { text {athletes} } } }`}
                        render={(data) => (<h2 className="text-center text-white  mt-0">{data.site.section.text.athletes}</h2>)} />

                    <hr className="divider my-4" />
                    <div className="row row-cols-1 row-cols-md-3">
                        {items.map(item => this.renderItem(item))}
                    </div>
                </div>
            </section>
        )
    }
}

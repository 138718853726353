import React, { Component } from 'react'
import PortfolioModal from "../components/portfolio/modal"
import PortfolioCarousel from "../components/portfolio/carousel"
import Img from "gatsby-image"
import { StaticQuery, graphql } from "gatsby"

export default class Gallery extends Component {

    state = {
        show: false,
        current: 0
    }

    handleImageClick = (index, e) => {
        e.preventDefault();
        this.setState({ show: true, current: index });
    }

    renderItem(item, index) {
        const { name, image: { childImageSharp: { original, preview } } } = item;
        return (
            <div className="col-lg-4 col-sm-6" onClick={(e) => this.handleImageClick(index, e)}>
                <a className="portfolio-box" href={original.src}>
                    <Img fluid={preview} />
                    <div className="portfolio-box-caption">
                        <div className="project-category text-white-50">{name}</div>
                    </div>
                </a>
            </div>
        )
    }

    render() {
        const { show, current } = this.state;
        const { items } = this.props;

        return (
            <section id="portfolio" className="page-section">
                <div className="container-fluid p-0">
                    <StaticQuery
                        query={graphql`query { site: dataYaml { section { text {gallery} } } }`}
                        render={(data) => (<h2 className="text-center text-white  mt-0">{data.site.section.text.gallery}</h2>)} />
                    <hr className="divider my-4" />
                    <div className="row no-gutters">
                        {items.map((item, index) => this.renderItem(item, index))}
                    </div>
                </div>
                <PortfolioModal show={show} onHide={() => this.setState({ show: false, current: 0 })}>
                    <PortfolioCarousel items={items} current={current} />
                </PortfolioModal>
            </section>
        )
    }
}
